<template>
    <b-container fluid class="background">
      <b-row class="description desc-right">
        <b-col cols="12" lg="6" class="text-left my-auto mr-5">
          <h2 class="my-5"><i class="fas fa-wrench"></i>{{ machines.machineOne.name }}</h2>
          <b-row>
            <b-table striped hover dark borderless :items="boringMachines" :fields="boringMachinesF"></b-table>
          </b-row>
        </b-col>
        <b-col cols="12" lg="5"><img class="title" :src="require('../assets/Picture7.png')" alt="Horizontal milling and boring machine"></b-col>
      </b-row>
      <b-row class="description desc-top">
        <b-col cols="12" class="text-left my-5"><h2><i class="fas fa-wrench"></i>{{ machines.machineThree.name }}</h2></b-col>
        <b-col cols="12"><b-table striped hover dark borderless :items="millingMachines" :fields="millingMachinesF"></b-table></b-col>
      </b-row>
      <b-row class="description desc-left">
        <b-col cols="12" lg="6" order="2" order-lg="1" class="my-auto"><img class="title" :src="require('../assets/Picture81.jpg')" alt="Turning machine"></b-col>
        <b-col cols="12" lg="4" offset-lg="1" order="1" order-lg="2" class="text-left">
          <h2 class="my-5"><i class="fas fa-wrench"></i>{{ machines.machineTwo.name }}</h2>
          <b-row>
            <b-table striped hover dark borderless="" :items="turningMachines" :fields="turningMachinesF"></b-table>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Machinery',
  data() {
    return {
      descriptionText: {},
      presentationImages: [],
      machines: {
        machineOne: {
          name: 'Horizontalno vrtalno rezkalni stroji',
          img: ''
        },
        machineTwo: {
          name: 'Stružnice',
          img: ''
        },
        machineThree: {
          name: 'Rezkalni stroji'
        }
      },
      boringMachinesF: [
        { key: 'miza', label: 'Miza' },
        { key: 'mere', label: 'x/y/z' },
        { key: 'vreteno', label: 'Vreteno' },
      ],
      boringMachines: [
        { miza: '2000 x 1800', mere: '4000/2800/400', vreteno: '130' },
        { miza: '1600 x 1400', mere: '2400/1680/2400', vreteno: '120' },
        { miza: '1400 x 1250', mere: '2000/1320/1800', vreteno: '100' },
        { miza: '2000 x 1800', mere: '3000/1880/ -', vreteno: '120' },
        { miza: '1000 x 1000', mere: '1020/980/ -', vreteno: '100' },
        { miza: '1000 x 1000', mere: '1020/980/ -', vreteno: '100' }
      ],
      millingMachinesF: [
        { key: 'miza', label: 'Miza' },
        { key: 'mere', label: 'x/y/z' }
      ],
      millingMachines: [
        { miza: '1300 x 500', mere: '800/500/450' },
        { miza: '1000 x 500', mere: '1020/420/470' }
      ],
      turningMachinesF: [
        { key: 'dolzina', label: 'Stružna dolžina' },
        { key: 'premer', label: 'Premer struženja' }
      ],
      turningMachines: [
        { dolzina: '3000 mm', premer: '780 mm' },
        { dolzina: '2000 mm', premer: '640 mm' },
        { dolzina: '1200 mm', premer: '580 mm' }
      ]
    }
  },
  created() {
    this.descriptionText = this.$store.getters.getDescription.dimenzije
    let lan = this.$store.getters.getLanguage
    this.changeLang(lan)
  },
  computed: mapState(['language']),
  watch: {
    language(val) {
      this.descriptionText = this.$store.getters.getDescription.dimenzije
      this.changeLang(val);
    }
  },
  methods: {
    changeLang(lang) {
      if (lang === 'SLO') {
        this.machines.machineTwo.name = 'Stružnice'
        this.machines.machineOne.name = 'Horizontalno vrtalno rezkalni stroji'
        this.machines.machineThree.name = 'Rezkalni stroji'
        this.boringMachinesF= [
          { key: 'mere', label: 'x/y/z' },
          { key: 'miza', label: 'Miza' },
          { key: 'vreteno', label: 'Vreteno' }
        ]
        this.millingMachinesF= [
          { key: 'miza', label: 'Miza' },
          { key: 'mere', label: 'x/y/z' }
        ]
        this.turningMachinesF= [
          { key: 'dolzina', label: 'Stružna dolžina' },
          { key: 'premer', label: 'Premer struženja' }
        ]
      } else if (lang === 'ENG') {
        this.machines.machineTwo.name = 'Turning machines'
        this.machines.machineOne.name = 'Horizontal milling and boring machines'
        this.machines.machineThree.name = 'Milling machines'
        this.boringMachinesF= [
          { key: 'mere', label: 'x/y/z' },
          { key: 'miza', label: 'Table' },
          { key: 'vreteno', label: 'Spindle' }
        ]
        this.millingMachinesF= [
          { key: 'miza', label: 'Table' },
          { key: 'mere', label: 'x/y/z' }
        ]
        this.turningMachinesF= [
          { key: 'dolzina', label: 'Turning length' },
          { key: 'premer', label: 'Turning diameter' }
        ]
      } else {
        this.machines.machineTwo.name = 'Drehmaschinen'
        this.machines.machineOne.name = 'Horizontale Bohr und Fräsmachinen'
        this.machines.machineThree.name = 'Fräsmaschinen'
        this.boringMachinesF= [
          { key: 'mere', label: 'x/y/z' },
          { key: 'miza', label: 'Tisch' },
          { key: 'vreteno', label: 'Spindel' }
        ]
        this.millingMachinesF= [
          { key: 'miza', label: 'Tisch' },
          { key: 'mere', label: 'x/y/z' }
        ]
        this.turningMachinesF= [
          { key: 'dolzina', label: 'Drehlänge' },
          { key: 'premer', label: 'Drehdurchmesser' }
        ]
      }
    }
  }
}
</script>

<style>
.title {
  width: 100%;
}
.fa-wrench {
  margin: 0 3%;
}
.desc-right {
  border-left: none;
  border-right: 8px solid #0055a6;
  margin: 5% 0;
}
.desc-left {
  margin: 5% 0;
}
.desc-top {
  border-left: none;
  border-top: 8px solid #0055a6;
  margin: 5% 0;
}
thead {
  background-color: #0055a6;
}
.table-dark {
  background-color: #4a4a4a;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #636363;
}
</style>
